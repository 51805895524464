import React, { useState } from 'react';
import { Avatar, Button, Paper, Grid, Typography, Container } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Icon from './Icon';
import { GoogleLogin } from 'react-google-login';
import { useDispatch } from 'react-redux';
import { useNavigate, Navigate } from 'react-router-dom';
import { signin, signup, changePassword } from '../../actions/auth';
import logo from '../../images/logo.png';

import useStyles from './styles';
import Input from './Input';

const initialState = { firstName: '', lastName: '', email: '', password: '', confirmPassword: '' };

const Auth = () => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    const [isSignup, setIsSignup] = useState(false);
    const [isResetPassword, setIsResetPassword] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('profile'));

    const handleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);

    const handleSubmit = (e) => {
        e.preventDefault();

        if(isSignup){
            dispatch(signup(formData, navigate));
            setIsSignup(false);

        } else if (isResetPassword){
            dispatch(changePassword(formData));
        } else {
            dispatch(signin(formData, navigate));
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const switchMode = () => {
        setIsSignup((prevIsSignup) => !prevIsSignup);
        setShowPassword(false);
    };

    const switchResetPasswordMode = () => {
        setIsResetPassword((prevIsResetPassword) => !prevIsResetPassword);
        setShowPassword(false);
    };

    const googleSuccess = async (res) => {
        const result = res?.profileObj;
        const token = res?.tokenId;

        try {
            console.log("working");
            dispatch({ type: 'AUTH', data: { result, token } })
            navigate('/');
        } catch (error) {
            console.log(error);
        }
    };

    const googleError = () => {
        console.log("Google Sign In was unsuccessful. Try Again Later");
    };

    return ( user?.result?.name ? 
        <Navigate to="/" />
        :
        <Container component="main" maxWidth="xs">
                                <img className={classes.image} src={logo} alt="memories" height="10" />

            <Paper className={classes.paper} elevation={3}>
                <Typography variant="h5">{isResetPassword ? "Reset Password" : (isSignup ? "Sign Up" : "Sign In")}</Typography>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        {
                            isSignup && (
                                <>
                                    <Input name="firstName" label="First Name" handleChange={handleChange} autoFocus half />
                                    <Input name="lastName" label="Last Name" handleChange={handleChange} half />
                                </>
                            )}
                        <Input name="email" label="Email Address" handleChange={handleChange} type="email" />
                        <Input name="password" label="Password" handleChange={handleChange} type={showPassword ? "text" : "password"} handleShowPassword={handleShowPassword} />
                        { (isResetPassword || isSignup) && <Input name="confirmPassword" label="Repeat Password" handleChange={handleChange} type={showPassword ? "text" : "password"} handleShowPassword={handleShowPassword} /> }
                    </Grid>
                    
                    {
                        !isResetPassword ?
                    (
                    <>
                    <Button type="submit" fullWidth variant="contained" color="neutral" className={classes.submit}>
                        {isSignup ? 'Sign Up' : 'Sign In'}
                    </Button>
                    <GoogleLogin
                        clientId="607592565156-brnmo87bgng9dfl4v4j25eqmkiqptn1s.apps.googleusercontent.com"
                        render={(renderProps) => (
                            <Button className={classes.googleButton} color="neutral" fullWidth onClick={renderProps.onClick} disabled={renderProps.disabled} startIcon={<Icon />} variant="contained">
                                Google Sign In
                            </Button>
                        )}
                        onSuccess={googleSuccess}
                        onFailure={googleError}
                        cookiePolicy="single_host_origin"
                    />
                    </>
                    ) :
                    (
                        <>
                            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                                Reset Password
                            </Button>
                        </>
                    )
                }
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            {
                            !isResetPassword && (<>
                            <Button onClick={switchMode}>
                                { isSignup ? "Already have an account? Sign In" : "Don't have an account? Sign Up" }
                            </Button>
                            </>
                            )
                            }
                            {
                            !isSignup && (<>
                            <Button onClick={switchResetPasswordMode}>
                                { isResetPassword ? "Back" : "Forgotton password? Reset Password" }
                            </Button>
                            </>
                            )
                            }
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    )
}

export default Auth
