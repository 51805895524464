import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Alert from '@mui/material/Alert';


const API = axios.create({ baseURL: 'https://dailyjrnl.herokuapp.com/' });
const MySwal = withReactContent(Swal)


API.interceptors.request.use((req) => {
    if(localStorage.getItem('profile')){
        req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
    }

    return req;
});

export const fetchPosts = () => API.get('/posts');
export const createPost = (newPost) => API.post('/posts', newPost);
export const updatePost = (id, updatedPost) => API.patch( `/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);

export const signIn = (formData) => API.post('/user/signin', formData).catch((error) => {
    MySwal.fire({
        title: <strong>Error!</strong>,
        html: <i>{error.response.data.message}</i>,
        icon: 'warning'
      })
  });
export const signUp = (formData) => API.post('/user/signup', formData).then((res) => {
	MySwal.fire({
			title: <strong>Success!</strong>,
			html: <i>{res.data.message}</i>,
			icon: 'success'
		})
	}).catch((error) => {
	MySwal.fire({
			title: <strong>Error!</strong>,
			html: <i>{error.response.data.message}</i>,
			icon: 'warning'
		})
});
export const resetPassword = (formData) => API.post('/user/forgotten-password', formData).then((res) => {
    MySwal.fire({
        title: <strong>Success!</strong>,
        html: <i>{res.data.message}</i>,
        icon: 'success'
      })
		}).catch((error) => {
				MySwal.fire({
						title: <strong>Error!</strong>,
						html: <i>{error.response.data.message}</i>,
						icon: 'warning'
				})
		});