import React from 'react';
import { Card, CardActions, CardContent, CardMedia, Button, Box, Typography, Modal } from '@material-ui/core';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ThumbUpAltOutlined from '@material-ui/icons/ThumbUpAltOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useDispatch } from 'react-redux';
import { deletePost, likePost } from '../../../actions/posts.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import moment from 'moment';

import useStyles from './styles.js';

const Post = ({post, setCurrentId, openModal, closeModal}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [openEntry, setOpenEntry] = React.useState(false);
    const user = JSON.parse(localStorage.getItem('profile'));

    const MySwal = withReactContent(Swal);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '25px',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      };

    const handleOpenEntry = () => {
        setOpenEntry(true);
      };

    const handleCloseEntry = () => {
        setOpenEntry(false);
    };

    const edit = () => {
        setCurrentId(post._id);
        openModal();
    }

    const deletePostButton = () => {

        MySwal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
        			dispatch(deletePost(post._id));
              MySwal.fire(
                'Deleted!',
                'Your entry has been deleted.',
                'success'
              )
            }
          });

    }

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography variant="h5" gutterBottom>{moment(post.createdAt).format('LL')} entry</Typography>
            </CardContent>
            <CardContent>
                <Typography class="pb-4" variant="body2" color="textSecondary" component="p" >{ post.message.length > 50 ? post.message.substring(0,80) + "..." : post.message }</Typography>
            <CardActions className={classes.cardActions}>
                <Button size="small" color="neutral" onClick={() => deletePostButton()}>
                    <Box m={1} mr={1}>
                        <DeleteIcon fontSize="small" />
                    </Box>
                    Delete
                </Button>
                <Button size="small" color="neutral" onClick={() => edit()}>
                    <Box m={1} mr={1}>
                        <EditIcon fontSize="small" />
                    </Box>
                    Edit
                </Button>
                <Button size="small" color="neutral" onClick={handleOpenEntry}>
                    <Box m={1} mr={1}>
                        <VisibilityIcon fontSize="small" />
                    </Box>
                    View
                </Button>
            </CardActions>

            <Modal
                open={openEntry}
                onClose={handleCloseEntry}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, width: '50%', height: '70%' }} style={{ overflow: "hidden", overflowY: "scroll",backgroundColor: "#fafafa"}}>
                    <Typography variant="h4" gutterBottom>{moment(post.createdAt).format('LL')} entry</Typography>
                    <Box>
                        <Typography variant="body1" style={{whiteSpace: 'pre-line'}} gutterBottom>{post.message}</Typography>
                    </Box>
                </Box>
            </Modal>

            </CardContent>
        </Card>
    );
}

export default Post;