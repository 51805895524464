import React, {useEffect, useState} from 'react';
import { Container, Grow, Grid, Toolbar, Typography, Button, Modal } from '@material-ui/core';
import {useDispatch} from 'react-redux';
import { Navigate, useNavigate, useLocation, Link } from 'react-router-dom';
import decode from 'jwt-decode';

import Posts from '../Posts/Posts';
import Form from '../Form/Form';
import Navbar from '../Navbar/Navbar';
import {getPosts} from '../../actions/posts';
import useStyles from '../Navbar/style.js';


const Home = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const [currentId, setCurrentId] = useState(null);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const classes = useStyles();

    const logout = () => {
        dispatch({ type: 'LOGOUT' });

        navigate('/login');
        setUser(null);
    }

    useEffect(() => {
        const token = user?.token;
        dispatch(getPosts());

        if (token) {
            const decodedToken = decode(token);

            if (decodedToken.exp * 1000 < new Date().getTime()){
                logout();
            } 
        }

        setUser(JSON.parse(localStorage.getItem('profile')));

    }, [currentId, dispatch, location]);

    return (
        !user?.result?.name ? 
        <Navigate to="/login" /> 
        :
            <Container>
                <Grid>
                    <Navbar currentId={currentId} setCurrentId={setCurrentId} open={open} openModal={handleOpen} closeModal={handleClose}/>
                    <Grow in>
                        <Grid>
                            <Posts setCurrentId={setCurrentId} openModal={handleOpen} />
                        </Grid>
                    </Grow>
                </Grid>
            </Container>
    )
}

export default Home
