import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Button, Typography, Box } from "@material-ui/core";

import Post from './Post/Post.js';
import useStyles from './styles.js';

const Posts = ({ setCurrentId, openModal }) => {
    const posts = useSelector((state) => state.posts);
    const classes = useStyles();

    return (
        posts.length ?  
            (<Grid className={classes.container} container spacing={3}>
                {posts.map((post) => (
                    <Grid key={post._id} item xs={12} sm={6} md={4}>
                        <Post post={post} setCurrentId={setCurrentId} openModal={openModal} />
                    </Grid>
                ))}
            </Grid>)
        : 

        <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}>
            <Grid item m={3}>
                <Typography variant="h4" >You have no entries!</Typography>
            </Grid>   
        </Grid> 
    );
}

export default Posts;