import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Paper, Container } from '@material-ui/core';
import FileBase from 'react-file-base64';
import { useDispatch, useSelector } from 'react-redux';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

import useStyles from './styles.js';
import { createPost, updatePost, getPosts } from '../../actions/posts.js';

const Form = ( { currentId, setCurrentId, closeModal, openModal}) => {
    const [postData, setPostData] = useState({ title: '', message: '', tags: '', selectedFile: '' });
    const post = useSelector((state) => currentId ? state.posts.find((p) => p._id === currentId) : null);
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('profile'));

    const MySwal = withReactContent(Swal);

    useEffect(() => {
        if(post) setPostData(post);
    }, [post])

    const handleSubmit = (e) => {
        e.preventDefault();

        if(currentId){
            dispatch(updatePost(currentId, { ...postData, name: user?.result?.name }));
        } else {
            if(!postData.message){
                MySwal.fire({
                    title: <strong>Error!</strong>,
                    html: <i>Please enter something before submitting</i>,
                    icon: 'warning'
                }).then((result) => {
                    if (result.isConfirmed) {
                        openModal();
                    }
                  });
            } else {
                dispatch(createPost({ ...postData, name: user?.result?.name }));
                dispatch(getPosts());
            }
        }

        closeModal();

        clear();
    }

    const clear = () => {
        setCurrentId(null);
        setPostData({ title: '', message: '', tags: '', selectedFile: '' });
    }    

    if (!user?.result?.name) {
        return (
            <Paper className={classes.paper}>
                <Typography variant="h6" align="center">
                    In order to make a journal entry you have to be signed in!
                </Typography>
            </Paper>
        )
    }

    return (
        <Container component="main" maxWidth="sm">
            <Paper className={classes.paper}>
                <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit}>
                    <Typography variant="h6">{currentId ? 'Edit' : 'Add'} Entry</Typography>
                    <TextField name="message" variant="outlined" required label="Journal entry" multiline rows={12} fullWidth value={postData.message} onChange={(e) => setPostData({ ...postData, message: e.target.value })} />
                    <Button className={classes.buttonSubmit} variant="contained" color="primary" size="large" type="submit" fullWidth>{currentId ? 'Edit' : 'Create'} Post</Button>
                    <Button variant="contained" color="secondary" size="small" onClick={clear} fullWidth>Clear</Button>
                </form>
            </Paper>
        </Container>
    );
}

export default Form;