import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Typography, Toolbar, Avatar, Button, Modal } from '@material-ui/core';

import { useDispatch } from 'react-redux';
import decode from 'jwt-decode';
import Form from '../Form/Form';

import memories from '../../images/memories.png';
import logo from '../../images/logo.png';
import useStyles from './style.js';

const Navbar = ({ currentId, setCurrentId, open, openModal, closeModal }) => {
    const classes = useStyles();
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const addEntry = () => {
        setCurrentId(null);
        openModal();
    }

    const logout = () => {
        dispatch({ type: 'LOGOUT' });

        navigate('/login');
        setUser(null);
    }

    useEffect(() => {
        const token = user?.token;

        if (token) {
            const decodedToken = decode(token);

            if (decodedToken.exp * 10000 < new Date().getTime()) logout();
        }

        setUser(JSON.parse(localStorage.getItem('profile')));

    }, [location])

    return (
        <AppBar className={classes.appBar} color="inherit">
            <Toolbar className={classes.toolbar}>
                <Button variant="contained" className={classes.logout} color="neutral" size="small" onClick={logout}>Logout</Button>
                <img className={classes.image} src={logo} alt="memories" height="10" />

                {user ? (
                        <div>
                            <Button variant="contained" className={classes.logout} color="neutral" size="small" onClick={addEntry}>New entry</Button>
                            <Modal
                                    open={open}
                                    onClose={closeModal}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                >
                                    <Form currentId={currentId} setCurrentId={setCurrentId} closeModal={closeModal} openModal={openModal} />
                                </Modal>
                        </div>
                    ) : (
                        <Button component={Link} to="/login" variant="contained" color="primary">Sign In</Button>
                    )}
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
