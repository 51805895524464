import React from 'react';
import { Container } from '@material-ui/core';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {Helmet} from "react-helmet";

import Navbar from './components/Navbar/Navbar.js';
import Home from './components/Home/Home.js';
import Auth from './components/Auth/Auth.js';

const App = () => {
    return(
        <BrowserRouter>
            <Container maxwidth="lg">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>DailyJrnl</title>
                </Helmet>
                <Routes>
                    <Route path="/login" element={<Auth/>} />
                    <Route path="/" element={<Home/>} />
                </Routes>
            </Container>
        </BrowserRouter>
    )
}

export default App;